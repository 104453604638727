@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap');

.hide-scrollbar {
  -ms-overflow-style: none; /* Edge, Internet Explorer */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

@layer components {
  .max-width {
    @apply max-w-[500px] w-full;
  }
  .page-header-wrapper {
    @apply relative pt-[75px] h-full border-0;
  }
  .list-wrapper {
    @apply p-4 pb-20 overflow-y-scroll h-full;
    @apply hide-scrollbar;
  }
}

html,
body,
#root,
.App {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

#root {
  @apply max-w-[500px] w-full;
}

body {
  font-family: 'DIN Alternate', 'Noto Sans TC', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  overflow-x: hidden;
}

.Toastify__toast-container--top-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  // padding-bottom: 65px;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}

.login-bg {
  background-image: linear-gradient(324deg, #4552cb 43.38%, #4596ea 92.17%);
}

.order-bg {
  position: absolute;
  height: 200px;
  z-index: -1;
  left: -1.25rem;
  right: -1.25rem;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.bill-circle-bg {
  height: 200px;
  z-index: -1;
  left: -1.25rem;
  right: -1.25rem;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

reach-portal {
  position: relative;
  z-index: 40;
}

.product-confirm-bg {
  width: calc(100% + 2.5rem);
  background-image: linear-gradient(
    324deg,
    #0d47a1 0%,
    #1e88e5 20%,
    #2196f3 35%,
    #64b5f6 55%,
    #bbdefb 88%,
    #e3f2fd 100%
  );
  left: -1.25rem;
  right: -1.25rem;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.modal-product-list {
  // max-height: 300px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

div[role='tabpanel'] {
  .tabPanel {
    display: none;
    animation: moving 0.5s ease;
    &.active {
      display: block;
    }
  }
}

@keyframes moving {
  0% {
    opacity: 0;
    // transform: translateX(400px);
  }
  100% {
    opacity: 1;
    // transform: translateX(0);
  }
}
